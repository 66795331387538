<div class="footer-container">
  <div class="footer__img-container">
    <img alt="logo Generali" src="./assets/icons/logos/logo-generali-white.svg">
    <img alt="logo Alleanza" src="./assets/icons/logos/logo-alleanza-white.svg">
    <img alt="logo Cattolica" src="./assets/icons/logos/logo-cattolica-white.svg">
    <img alt="logo Genertel" src="./assets/icons/logos/logo-genertel-white.svg">
    <img alt="logo Genertel life" src="./assets/icons/logos/logo-genertel-life-white.svg">
    <img alt="logo Welion" src="./assets/icons/logos/logo-welion-white.svg">
    <img alt="logo Jeniot" src="./assets/icons/logos/logo-jeniot-white.svg">
    <img alt="logo DAS" src="./assets/icons/logos/logo-das-white copy.svg">
  </div>
</div>
