import { Component, Input, inject } from "@angular/core";
import { OopsToastService } from "./oops-toast.service";

@Component({
  selector: "oops-toast",
  templateUrl: "./oops-toast.component.html",
  styleUrls: ["./oops-toast.component.scss"]
})
export class OopsToastComponent {
  protected toastService = inject(OopsToastService);
  @Input() hideAfter?: number;

  ngOnDestroy(): void {
    this.toastService.clear();
  }
}
