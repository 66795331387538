import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CounselorSlot } from "oops-cl";

@Component({
  selector: "oops-slot",
  templateUrl: "./oops-slot.component.html",
  styleUrls: ["./oops-slot.component.scss"]
})
export class OopsSlotComponent {
  @Input() date = new Date().toISOString();
  @Input() active?: boolean;
  @Input() slot?: CounselorSlot;

  @Output() onClick = new EventEmitter<CounselorSlot>();
}
