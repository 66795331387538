import { Component, EventEmitter, Output, Input, inject, ErrorHandler } from "@angular/core";
import { LoggerService } from "src/ancestors/logger.service";
import { UploadService } from "./upload-data.service";

@Component({
  selector: "oops-upload",
  templateUrl: "./oops-upload.component.html",
  styleUrls: ["./oops-upload.component.scss"]
})
export class OopsUploadComponent {
  protected logger = inject(LoggerService);
  protected errorHandler = inject(ErrorHandler);
  protected uploadService = inject(UploadService);

  @Input() acceptedMimeTypes = ".xls, .xlsx";
  @Input() title = "";
  @Output() onUpload = new EventEmitter<File>();
  
  // Gestione del file
  protected handleFileInput(event: Event) {
    this.uploadService.file = undefined;
    const { files } = event.target as HTMLInputElement;
    
    const file = files?.item(0);
    if (file) {
      this.onUpload.emit(file);
    }
  }

  protected allowDrag(event: DragEvent) {
    event.preventDefault();
  }

  protected handleFileInputOnDrag(event: DragEvent) {
    const files = event.dataTransfer?.files;
    const fileName = files?.item(0);
    const validatingFilesRegex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
    
    event.preventDefault();
    
    this.uploadService.file = undefined;

    if (fileName && !validatingFilesRegex.test(fileName.name.toLowerCase())) {
      this.errorHandler.handleError({ applicationError: "FILE_EXTENSION_NOT_SUPPORTED" });
      return;
    }

    const file = files?.item(0);
    if (file) {
      // this.uploadService.file = file;
      this.onUpload.emit(file);
    }
  }

  // Premi sul sfoglia files
  protected uploadDoc() {
    const element = document.querySelector("#uploadFile");
    if (element instanceof HTMLInputElement) {
      element.value = "";
      element.click();
    } else {
      this.logger.warn("Element with id 'uploadFile' is not an input element");
    }
  }
}
