import { ChangeDetectorRef, Injectable, inject } from "@angular/core";
import { Counselor, OOPSProcess } from "oops-cl";
import { BaseDataService } from "src/ancestors/base-data.service";
import { UrlCollection } from "src/app/app-routing.module";
import { RisultatoListaUtentiEstesa } from "src/app/global-service/backend-api.service";
import { Breadcrum } from "src/app/pages/user/banner-data.service";

@Injectable()
export class AdminService extends BaseDataService {
  protected cdr = inject(ChangeDetectorRef);
  constructor() {
    super();
  }

  public currentPageName = "admin.backoffice.HOME";
  public currentPageInfo = "admin.backoffice.INFO";
  public breadcrumbs: Breadcrum[] = [];
  public showDownloadExcel?: boolean;
  public showBanner = true;
  public serviceResponses: {
    counselorList?: Counselor[];
    counselorCount?: number;
    processeList?: OOPSProcess[];
    processeCount?: number;
  } = {};
  public developmentDataList: RisultatoListaUtentiEstesa = new RisultatoListaUtentiEstesa;
  public loader: {
    table?: boolean;
    buttons?: boolean;
  } = {};

  public setBreadcrumbForTimeManagment() {
    this.currentPageName = "admin.backoffice.TIME_MANAGMENT";
    this.currentPageInfo = "admin.backoffice.TIME_MANAGMENT_INFO";
    const breadcrum: Breadcrum[] = [
      { pageName: "admin.backoffice.HOME", redirectTo: UrlCollection.admin },
      { pageName: this.currentPageName }
    ];
    this.breadcrumbs = breadcrum;
    this.showDownloadExcel = true;
    this.cdr.detectChanges();
  }

  public setBreadcrumbForHomepage() {
    this.breadcrumbs = [];
    this.currentPageName = "admin.backoffice.HOME";
    this.currentPageInfo = "admin.backoffice.INFO";
    this.showDownloadExcel = false;
    this.cdr.detectChanges();
  }

  public setBreadcrumbForDBData() {
    const currentPageName = this.translateService.instant<string[]>(["admin.backoffice.DB_DATA"]);
    this.currentPageName = currentPageName.join(" ");
    this.currentPageInfo = "Lista Utenti";
    const breadcrum: Breadcrum[] = [
      { pageName: "admin.backoffice.PROCESS", redirectTo: UrlCollection.admin },
      { pageName: "variationBaseline" }
    ];
    this.breadcrumbs = breadcrum;
    this.showDownloadExcel = false;
    this.cdr.detectChanges();
  }

  public setBreadcrumbForUserDetail(name: string, surname: string, email: string, sedeLavoro: string) {
    const currentPageName = this.translateService.instant<string[]>([`${name} ${surname}`]);
    this.currentPageName = currentPageName.join(" ");
    this.currentPageInfo = `${email} - ${sedeLavoro}`;
    const breadcrum: Breadcrum[] = [
      { pageName: "admin.backoffice.PROCESS", redirectTo: "admin/db-data" },
      { pageName: "variationBaseline" }
    ];
    this.breadcrumbs = breadcrum;
    this.showDownloadExcel = false;
    this.cdr.detectChanges();
  }

  public setBreadcrumbForReport() {
    const currentPageName = this.translateService.instant<string[]>(["admin.backoffice.REPORT", "page.OOPS"]);
    this.currentPageName = currentPageName.join(" ");
    this.currentPageInfo = "";
    const breadcrum: Breadcrum[] = [
      { pageName: "admin.backoffice.HOME", redirectTo: UrlCollection.admin },
      { pageName: "admin.backoffice.REPORT" }
    ];
    this.breadcrumbs = breadcrum;
    this.showDownloadExcel = false;
    this.cdr.detectChanges();
  }

  public async getCounselorForAdmin(searchedText: string, fromRecord: number, numRecords: number) {
    this.loader.table = true;
    const [counselorList, counselorCount] = await Promise.all([
      this.backendService.consuelorAdminList(searchedText, fromRecord, numRecords),
      this.backendService.consuelorAdminCount(searchedText)
    ]);

    this.loader.table = false;
    return { counselorList, counselorCount };
  }

  public async getProcesseForAdmin(fromRecord: number, numRecords: number) {
    this.loader.table = true;
    const [processeList, processeCount] = await Promise.all([
      this.backendService.adminProcessesList(fromRecord, numRecords),
      this.backendService.adminProcessesCount()
    ]);
    this.loader.table = false;

    return { processeList, processeCount };
  }

  /* DB Data development */
  public async getDBDevelopmentDataForAdmin(fromRecord?: number, numRecords?: number, cid?: string, surname?: string, managerSurname?: string) {
    this.loader.table = true;
    const [response] = await Promise.all([
      this.backendService.adminDBDevelopmentDataList(fromRecord, numRecords, cid, surname, managerSurname)
    ]);

    this.loader.table = false;
    return { response };
  }

  public async getDBDevelopmentGroups(userId: number) {
    const [developmentDataGroups] = await Promise.all([
      this.backendService.adminDBDevelopmentDataGroups(userId)
    ]);

    return { developmentDataGroups };
  }

  public async postDBDevelopmentGroups(body: object) {
    const [developmentDataGroups] = await Promise.all([
      this.backendService.adminDBDevelopmentEditDataGroups(body)
    ]);

    return { developmentDataGroups };
  }

  /* DB Data development user info */
  public async getDBDevelopmentUserInfo(id: number | string) {
    const [UserInfoData] = await Promise.all([
      this.backendService.adminDBDevelopmentUserInfo(id)

    ]);

    return { UserInfoData };
  }

  /* DB Data development user info breadcrumbs*/
  public async getDBDevelopmentUserInfoBreadcrumbs(cid: number | string) {
    const [data] = await Promise.all([
      this.backendService.adminDBDevelopmentUserBreadcrumbs(cid)

    ]);

    return { data };
  }




  /* DB Data development save user info */
  public async postDBDataUserInfo(body: object) {
    const [response] = await Promise.all([
      this.backendService.adminPostDBDevelopmentUserInfo(body)

    ]);

    return { response };
  }

  /* DB Data development get edit access*/
  public async getUsersEditAccess(body: object) {
    const [editResponse] = await Promise.all([
      this.backendService.getUsersEditAccess(body)

    ]);

    return { editResponse };
  }

  public async getGroupsEditAccess(body: object) {
    const [editResponse] = await Promise.all([
      this.backendService.getGroupsEditAccess(body)

    ]);

    return { editResponse };
  }
}
