<div class="oops-table" [class.stripes]="stripes">
  <table>
    <thead class="table__header">
      <tr>
        <ng-container *ngIf="headTmp" [ngTemplateOutlet]="headTmp"></ng-container>
      </tr>
    </thead>
    <tbody class="table__body">
      <tr *ngFor="let item of items; let i=index let last=last let first=first">
        <ng-container *ngIf="bodyTmp" [ngTemplateOutlet]="bodyTmp" [ngTemplateOutletContext]="{ $implicit: item, index: i, last, first }"></ng-container>
      </tr>
    </tbody>
  </table>
</div>
