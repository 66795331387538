import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

import { OOPSLoginInfo } from "oops-cl";
import { EnvironmentLoaderService, GeneralConfig } from "src/ancestors/env-config.service";
import { UrlCollection } from "../app-routing.module";
import { BackendService } from "./backend-api.service";
import { TokenManagerService } from "./token-manager.service";
import { TokenStorageService } from "./token-storage.service";
import { ApplicationError } from "sharedclasses";

@Injectable({ providedIn: "root" })
export class AuthenticationGuard  {
  constructor() { }
  private router: Router = inject(Router);
  private envConfig: EnvironmentLoaderService = inject(EnvironmentLoaderService);
  private tkManager: TokenManagerService = inject(TokenManagerService);
  private tStorage: TokenStorageService = inject(TokenStorageService);
  private backendService = inject(BackendService);
  private env: GeneralConfig = this.envConfig.getEnvConfig();

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const tokenKey = this.env.loginType === "local" ? this.env.localTokenKey : this.env.ssoTokenKey;
    const actualUrl = new URL(window.location.href.replace("/#/", "/"));
    const tokenExist = JSON.stringify(this.tStorage.retriveToken(tokenKey)) === "{}" ? false : true;
    const ssortkqp = actualUrl.searchParams.get("ssortkqp");

    // Se sono qui vuol dire che è già presente un token e sto facendo un nuovo accesso
    // Elimino il token e ricarico la pagina per recuperare e usare il nuovo token
    if (tokenExist && ssortkqp) {
      this.tStorage.deleteToken(tokenKey);
      window.location.reload();
      return true;
    }

    // Se sto tornando dall'SSO e ho il codice di login, provo a decodificarlo
    if (!tokenExist && ssortkqp) {
      const loginInfo = await this.backendService.decodeSingleUseToken(ssortkqp);
      if (!loginInfo) {
        throw new ApplicationError("Invalid login");
      }

      // Se arrivo qui il login è buono, quindi mando l'utente alla pagina che ha richiesto originarimanete o alla homepage
      this.tStorage.saveToken(tokenKey, loginInfo);
      // Vado alla pagina e rimuovo i parametri dall'url ssortkqp
      window.location.href = window.location.href.split("?")[0];
      return true;
    }

    // Se arrivo qui, invece, o sono già autenticato oppure non lo sono per niente (non sto tornando dall'SSO)
    // Quindi cerco se ho un token
    const token: OOPSLoginInfo = this.tStorage.retriveToken(tokenKey);
    const tokenIsValid: boolean = this.tkManager.checkTokenValidity(token);
    // In base al tipo di storage per il token, controllo se c'è e se manca invio l'utente al login
    switch (this.env.storeAccessToken) {
      case "cookies": {
        if (token && tokenIsValid && !ssortkqp) {
          return true;
        }
        // Non si è autentica, quindi via al lognin
        await this.redirectToLoginUrl(state.url);
        return false;
      }

      default /* sessionstorage */: {
        if (token && tokenIsValid) {
          return true;
        }
        // Non si è autentica, quindi via al lognin
        // E l'evenntuale token scaduto verrà eliminato
        this.tStorage.deleteToken(tokenKey);
        await this.redirectToLoginUrl(state.url);
        return false;
      }
    }
  }

  /**
   * Fa il redirect all'url di login, in base al tipo di login
   * aggiungendo anche l' originalRequestedUrl per tornare all'url originale dopo il login
   *
   * @param urlSnapshot URL alla qualle si tenta di accedere.
   */
  async redirectToLoginUrl(urlSnapshot: string): Promise<void> {
    switch (this.env.loginType) {
      case "local": {
        await this.router.navigate([this.env.loginUrl], {
          queryParams: { originalRequestedUrl: urlSnapshot }
        });
        break;
      }
      case "saml-sso": {
        const actualUrl = new URL(window.location.href.replace("/#/", "/"));
        const redirectUrl = new URL(actualUrl);
        const baseHref = this.env.baseHref;

        /** aggiunge l'url originale in cui tornare dopo il login */
        let originalRequestedUrl = redirectUrl.pathname === "/" ? undefined : redirectUrl.pathname;

        if (baseHref && originalRequestedUrl?.startsWith(baseHref)) {
          originalRequestedUrl = originalRequestedUrl.substring(baseHref.length);
        }

        await this.router.navigate([UrlCollection.samlSso], { queryParams: { originalRequestedUrl } });
        break;
      }
      default: {
        await this.router.navigate([this.env.loginUrl], {
          queryParams: { originalRequestedUrl: urlSnapshot }
        });
      }
    }
  }

  private getRedirectUrl(url: string) {
    const actualUrl = new URL(url.replace("/#/", "/"));
    const baseHref = this.env.baseHref;
    const originalRequestedUrl = actualUrl.searchParams.get("originalRequestedUrl");
    let redirectUrl: string | null = originalRequestedUrl ? originalRequestedUrl : (actualUrl.pathname === "/" ? null : actualUrl.pathname);

    if (baseHref && redirectUrl?.startsWith(baseHref)) {
      redirectUrl = redirectUrl.substring(baseHref.length);
    }
    return redirectUrl;
  }
}