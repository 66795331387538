import { Component, Input, TemplateRef } from "@angular/core";

@Component({
  selector: "oops-table",
  templateUrl: "./oops-table.component.html",
  styleUrls: ["./oops-table.component.scss"]
})
export class OopsTableComponent {
  @Input() items?: any[];
  @Input() stripes?: boolean;
  @Input() headTmp?: TemplateRef<any>;
  @Input() bodyTmp?: TemplateRef<any>;
}
