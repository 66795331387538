import { ElementRef, Input, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "truncate"
})
export class TruncatePipe implements PipeTransform {
  @Input("appTruncate") maxLength = 200;

  constructor(private elementRef: ElementRef) { }
  transform(value?: string, limit = 20): string {
    if (!value) return "";

    if (value.length <= limit) {
      return value;
    }

    return value.substr(0, limit);
  }

}
