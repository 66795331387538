import { Injectable, inject } from "@angular/core";
import { Counselor, CounselorSlot } from "oops-cl";
import { BaseDataService } from "src/ancestors/base-data.service";
import { UrlCollection } from "src/app/app-routing.module";
import { UserService } from "src/app/global-service/user.service";

@Injectable()
export class UserDataService extends BaseDataService {
  private usrService = inject(UserService);
  constructor() {
    super();
    this.logger.setCaller("UserDataService");
  }

  public serviceResponses: {
    counselorList?: Counselor[];
    counselorCount?: number;
    counselorSlotList?: CounselorSlot[];
    counselorSlotCount?: number;
    counselorSlotSelected?: CounselorSlot & { isPossibleToCancel?: boolean };
    counselorSelected?: Counselor;
  } = {};

  public loader: {
    genericLoader?: boolean;
    cardLoader?: boolean;
    slotLoader?: boolean
  } = {};

  public async getCouncelor(searchedText: string, fromRecord: number, numRecords: number) {
    this.loader.cardLoader = true;

    const [counselorList, counselorCount] = await Promise.all([
      this.backendService.counselorList(searchedText, fromRecord, numRecords),
      this.backendService.counselorCount(searchedText)
    ]);

    this.loader.cardLoader = false;
    return { counselorList, counselorCount };
  }

  public async getCouncelorSlot(counselorId: string) {
    this.loader.slotLoader = true;

    const [counselorSlotList, counselorSlotCount] = await Promise.all([
      this.backendService.counselorSlotList(counselorId),
      this.backendService.counselorSlotCount(counselorId)
    ]);

    this.loader.slotLoader = false;
    return { counselorSlotList, counselorSlotCount };
  }

  public async userBookAppointment(counselorSlotId: string) {
    return this.backendService.userBookAppointment(counselorSlotId);
  }

  public async getReservationSlotById(counselorSlotId: string): Promise<CounselorSlot & { isPossibleToCancel: boolean }> {
    this.loader.genericLoader = true;

    if (this.usrService.isAdmin()) {
      const counselorSlot = await this.backendService.getAdminSlotReservation(counselorSlotId);
      if (counselorSlot && !counselorSlot.counselorSlotId) {
        await this.router.navigate([UrlCollection.admin]);
      }
      this.loader.genericLoader = false;
      return counselorSlot;
    } else if (this.usrService.isCounselor()) {
      const counselorSlot = await this.backendService.getCounselorSlotReservation(counselorSlotId);
      this.loader.genericLoader = false;
      return counselorSlot;
    } else {
      const counselorSlot = await this.backendService.getUserSlotReservation(counselorSlotId);
      this.loader.genericLoader = false;
      return counselorSlot;
    }
  }

  public async cancelAppointment(counselorSlotId: string) {
    if (this.usrService.isAdmin()) {
      return this.backendService.adminCancelAppointment(counselorSlotId);
    } else if (this.usrService.isCounselor()) {
      return this.backendService.counselorCancelAppointment(counselorSlotId);
    } else {
      return this.backendService.userCancelAppointment(counselorSlotId);
    }
  }
}