/**
 * Exception di applicazione.
 * Questa classe crea un oggetto ApplicationError per standardizzare ed estendere le eccezioni.
 *
 * Per utilizzarlo in un componente:
 * 1. Importarlo
 * 2. Per utilizzarlo usare throw new ApplicationError('exception message');
 */

import { ErrorHandler, Injectable, Injector, inject } from "@angular/core";
import { LoggerService } from "./logger.service";
import { TranslateService } from "src/app/shared/util/translate.service";
import { ApplicationHttpResponse } from "sharedclasses";
import { OopsToastService } from "src/app/shared/components/oops-toast/oops-toast.service";


export class HTTPError extends Error {
  /** Nome dell'errore usato come chiave nell'i18n */
  public errorKey?: string;

  constructor(err?: string) {
    super();

    this.errorKey = err;
  }
}

export class ClientError extends Error {
  /** Codice di errore */
  public httpErrorCode?: number;
  /** Descrizione errore */
  public applicationError?: string;
  /** Id errore */
  public messageFromError?: Record<string, unknown> | string;

  constructor(applicationError: ApplicationHttpResponse<unknown>) {
    super();

    this.applicationError = applicationError?.error;
    this.httpErrorCode = applicationError?.httpErrorCode;
    this.messageFromError = applicationError?.messageFromError;
  }
}

export class ApplicationError extends Error {
  /** Descrizione errore */
  public applicationError?: string;
  /** Codice di errore */
  public httpErrorCode?: number;
  /** Id errore */
  public messageFromError?: Record<string, unknown> | string;
  /** Nome dell'errore usato come chiave nell'i18n */
  public errorKey?: string;
  /** Nome del servizio dalla quale proviene l'errore */
  public serviceName: string;
  /** Nome del metodo dalla quale proviene l'errore */
  public method: string;

  constructor(error: unknown, method: string, serviceName: string) {
    super();

    this.method = method;
    this.serviceName = serviceName;

    if (error instanceof ClientError) {
      this.applicationError = error.applicationError;
      this.httpErrorCode = error.httpErrorCode;
      this.messageFromError = error.messageFromError;
    }
    if (error instanceof HTTPError) {
      this.errorKey = error.errorKey;
    }
  }
}

@Injectable()
export class GlobalError implements ErrorHandler {
  private injector = inject(Injector);

  constructor() { }

  /**
   *
   * Gestore centralizzato degli errori
   *
   * @param error
   */
  public handleError({ httpErrorCode, errorKey, messageFromError, applicationError, method, serviceName, name }: ApplicationError) {
    const logger = this.injector.get(LoggerService);
    const toast = this.injector.get(OopsToastService);
    const translate = this.injector.get(TranslateService);

    /* Errore proveniente dalla risposta della chiamata **/
    const hasErrorCode = translate.has(httpErrorCode?.toString());
    /* Errore proveniente dalla risposta della chiamata **/
    const hasApplicationError = translate.has(applicationError);
    /* Errore proveniente dal server **/
    const hasHttpError = translate.has(errorKey);
    const appendix = `From ${serviceName} -- Method ${method?.toUpperCase()}`;

    if (hasErrorCode || hasApplicationError) {
      const param = typeof messageFromError === "string"
        ? messageFromError
        : Object.values(messageFromError ?? [])?.join(", ");

      const translatedError = translate.instant<string>(
        httpErrorCode?.toString() ?? applicationError ?? "",
        {
          arg: { param: param }
        });

      toast.error(translatedError);
      logger.error(translatedError, appendix);
    }

    if (hasHttpError && errorKey) {
      const translatedError = translate.instant<string>(errorKey);
      toast.error(translatedError);
      logger.error(translatedError, appendix);
    }

    if (!hasHttpError && !hasErrorCode && !hasApplicationError) {
      toast.error(name);
      logger.error(name, appendix);
    }
  }
}
