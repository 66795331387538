<div class="content">
  <div class="header__wrapper">
    <!-- Burger + logo Generali -->
    <div class="header__logo-wrapper">
      <button role="button" [attr.aria-label]="'homepage.header.OPEN_SIDE_MENU' | translate" class="burgher-wrapper" (click)="onToggleMenu()">
        <div class="burgher"></div>
        <div class="burgher"></div>
        <div class="burgher"></div>
      </button>
      <button class="header__logo-img" (click)="onLogoClicked()" [attr.aria-label]="'homepage.header.GO_TO_HOME' | translate">
        <img alt="logo Generali" src="./assets/icons/logo-generali.svg">
      </button>
    </div>

    <!-- Nome applicazione -->
    <button class="header__name d-none d-sm-block" (click)="onLogoClicked()"
      [attr.aria-label]="'homepage.header.GO_TO_HOME' | translate">
      <img alt="logo DevelopmentUp" src="./assets/icons/logo-DevelopmentUP.svg">
      <!-- <p>{{'generic.DEVELOPMENTUP' | translate}}</p> -->
    </button>
    <div></div>
  </div>

  <div class="header-container">

  </div>
</div>

<!-- Linea divisoria -->
<!-- <div class="header__line"></div> -->

<oops-drawer [(show)]="showMenu" [items]="items" (onClick)="onRedirection.emit($event)">
</oops-drawer>