import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { EnvironmentLoaderService } from "../ancestors/env-config.service";
import { BaseComponent } from "../ancestors/base/base.component";
import { LoggerService } from "src/ancestors/logger.service";
import { BaseModule } from "../ancestors/base/base.module";
import { UserService } from "./global-service/user.service";
import { TokenManagerService } from "./global-service/token-manager.service";
import { i18nModule } from "../ancestors/i18n/i18n.module";
import { BackendService } from "src/app/global-service/backend-api.service";
import { ApplicationDataService } from "./global-service/application-data.service";
import { SharedModule } from "./shared/shared.module";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TokenStorageService } from "./global-service/token-storage.service";
import { GlobalError } from "src/ancestors/exception.service";
import { TranslateService } from "./shared/util/translate.service";
import { TruncatePipe } from "./shared/pipe/truncate.pipe";
// import { BannerService } from "./pages/user/banner-data.service";

/**
 *
 * @param envService Service responsabile del caricamento del file di configurazione
 * @returns File di configurazione.
 * Angular fornisce la possibilità di eseguire delle logiche di configurazione prima che l'applicazione venga inizializzata,
 * in questo modo possiamo caricare il file di configurazione delle variabili globali senza dover ricompilare l'applicazione.
 *
 */
const initAppFn = (envService: EnvironmentLoaderService) => {
  return () => envService.setAppConfig();
};

const translationInitializer = (translation: TranslateService) => {
  return () => {
    translation.loadTranslation();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BaseModule,
    ReactiveFormsModule
  ],
  providers: [
    BackendService,
    // BannerService,
    TokenStorageService,
    ApplicationDataService,
    TokenManagerService,
    UserService,
    LoggerService,
    EnvironmentLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: initAppFn,
      multi: true,
      deps: [EnvironmentLoaderService]
    },
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: translationInitializer,
      multi: true,
      deps: [TranslateService]
    },
    {
      provide: ErrorHandler,
      useClass: GlobalError
    },
    i18nModule.setLocale(),
    i18nModule.setLocaleId()
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
