import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";

import { BaseComponent } from "src/ancestors/base/base.component";
import { OOPSLoginInfo } from "oops-cl";
import { UrlCollection } from "./app-routing.module";
import { TranslateService } from "./shared/util/translate.service";
import { DrawerItems } from "./shared/components/oops-drawer/oops-drawer.component";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]

})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  protected fb: FormBuilder = inject(FormBuilder);
  protected translate = inject(TranslateService);

  constructor() {
    super();
    this.logger.setCaller("AppComponent");
    if (!window.location.href.includes(UrlCollection.user + "/" + UrlCollection.userHomepage) && !window.location.href.includes("localLogin")) {
      sessionStorage.setItem("originalRequestedUrl", window.location.href);
    }

  }

  protected showUserOption?: boolean;
  protected drawerItems: DrawerItems[] = [];

  // eslint-disable-next-line @typescript-eslint/require-await
  override async ngOnInit(): Promise<void> {
    const loginResposnse: OOPSLoginInfo = this.tStorage.retriveToken(this.tokenKey);

    /**
     * 
     * Se esiste un token ed è ancora valido schedula il rinnovo prima della scadenza
    */
    if (!this.appService.isEmpty(loginResposnse) && this.tkManager.checkTokenValidity(loginResposnse)) {
      this.tkManager.refreshTokenScheduler(loginResposnse);
    }

    if (!sessionStorage.getItem("originalRequestedUrl") && this.env.loginType === "saml-sso") {
      await this.router.navigate([UrlCollection.redirectTo]);
    }

    sessionStorage.removeItem("originalRequestedUrl");
  }

  protected loadMenuOptions() {
    this.drawerItems = [];
    this.drawerItems.push(
      {
        name: this.translate.instant("page.LOGOUT"),
        redirectTo: UrlCollection.localAuthentication,
        id: "LOGOUT"
      },
      {
        name: this.translate.instant("page.ADMIN"),
        redirectTo: UrlCollection.admin,
        canDisplay: this.usrService.isAdmin()
      },
      {
        name: this.translate.instant("generic.home", {
          transform: "capitalize"
        }),
        redirectTo: UrlCollection.user,
        canDisplay: this.usrService.isUser()
      }
    );
  }

  protected toggleUserOption() {
    if (this.showUserOption) {
      this.showUserOption = false;
    }

  }

  /** Questa funzione cambia la lingua a runtime, e fa il reload della pagina */
  private changeLang(locale: string) {

    /** Salva la lingua nel localStorage */
    localStorage.setItem("locale", locale);

    /** Fa il reload della pagina, per caricare il file json corretto */
    location.reload();
  }

  protected async onNavTabClick(path: DrawerItems) {
    if (path?.id === "LOGOUT") {
      return this.backendService.logout();
    }
    return this.router.navigate([path.redirectTo]);
  }

  protected async doLogout() {
    this.showUserOption = false;
    try {
      const redirectToLogin: string = sessionStorage.getItem("SSO_LOGIN") ? "Saml-sso/logoutSaml" : this.env.loginUrl;
      // cancello la variabile che mi dice se ho fatto il login con l'sso 
      sessionStorage.removeItem("SSO_LOGIN");
      await this.backendService.logout(redirectToLogin);
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
  }

  protected skipToContent() {
    const mainContent = document.getElementById("main-content");
    if (mainContent) {
      mainContent.focus();
    }
  }

}
