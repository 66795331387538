<div class="main" (click)="onClicked()">
  <!-- Immagine lato sinistro -->
  <img class="link__img" [src]="src">
  <!-- Titolo -->
  <p class="link__title">{{title}}</p>
  <!-- Parte destra con immagine e link -->
  <div class="link-container">
    <!-- Testo link -->
    <p class="link-text">{{linkText}}</p>
    <!-- Freccia -->
    <img src="./assets/icons/chevron-right-red.svg">
  </div>
</div>