<div class="generic-row-wrapper" (click)="emitOnCheck()">

  <!-- Checkbox -->
  <div class="welion-check-wrapper" [ngClass]="{'disabled': disabled, 'readonly': readonly, 'checked': checked}">
    <img *ngIf="checked" src="./assets/icons/check.svg" class="checkmark" style="fill: red;">
  </div>

  <!-- Label -->
  <h6 class="light-grey" *ngIf="label && !customLabel">{{ label }}</h6>

  <!-- Custom label -->
  <ng-container *ngIf="customLabel" [ngTemplateOutlet]="customLabel">
  </ng-container>

</div>