import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ForbiddenComponent } from "./pages/forbidden/forbidden.component";
import { UserComponent } from "./pages/user/user.component";
import { AdminComponent } from "./pages/admin/admin.component";
import { AuthenticationGuard } from "./global-service/authentication.guard";
import { RedirectToRightHomepageGuard, CounselorGuard } from "./global-service/authorization.guard";

export namespace UrlCollection {
  export const localAuthentication = "local-authentication";
  export const passwordRecovery = "password-recovery";
  export const samlSso = "Saml-sso";
  export const notAuthorized = "not-authorized";
  export const notFound = "not-found";
  export const redirectTo = "";
  export const redirectRequest = "redirectRequest";
  export const forbidden = "403";

  // User
  export const user = "user";
  export const userHomepage = "homepage";
  export const oops = "oops";
  export const reservation = "reservation";
  export const reservationOverview = "reservation-overview";

  // Admin
  export const admin = "admin";
  export const timeManagment = "time-managment";
  export const adminReport = "admin-report";
  export const adminHomepage = "homepage";
  export const adminDBData = "db-data";
  export const adminUserDetail = "user-detail/:id";
  export const adminDBSetup = "db-setup";


  // Counselor
  export const counselor = "counselor";
}

const routes: Routes = [
  { path: "", redirectTo: UrlCollection.user, pathMatch: "full" },
  // Modulo User
  {
    path: UrlCollection.user,
    component: UserComponent,
    canActivate: [AuthenticationGuard, RedirectToRightHomepageGuard],
    loadChildren: async () => {
      const m = await import("./pages/user/user.module");
      return m.UserModule;
    }
  },
  // Modulo Counselor
  {
    path: UrlCollection.counselor,
    component: UserComponent,
    canActivate: [AuthenticationGuard, RedirectToRightHomepageGuard],
    canActivateChild: [CounselorGuard],
    loadChildren: async () => {
      const m = await import("./pages/user/user.module");
      return m.UserModule;
    }
  },
  // Modulo Admin
  {
    path: UrlCollection.admin,
    component: AdminComponent,
    canActivate: [AuthenticationGuard, RedirectToRightHomepageGuard],
    loadChildren: async () => {
      const m = await import("./pages/admin/admin.module");
      return m.AdminModule;
    }
  },
  {
    path: UrlCollection.samlSso,
    loadChildren: async () => {
      const m = await import("./pages/saml-sso/saml-sso.module");
      return m.SamlSsoModule;
    }
  },
  {
    path: UrlCollection.localAuthentication,
    loadChildren: async () => {
      const m = await import("./pages/local-authentication/local-authentication.module");
      return m.LocalAuthenticationModule;
    }
  },
  {
    path: UrlCollection.passwordRecovery,
    loadChildren: async () => {
      const m = await import("./pages/password-recovery/password-recovery.module");
      return m.PasswordRecoveryModule;
    }
  },
  { path: "**", redirectTo: UrlCollection.notFound },
  { path: UrlCollection.forbidden, component: ForbiddenComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: "top" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
