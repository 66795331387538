import { Component } from "@angular/core";

@Component({
  selector: "oops-footer",
  templateUrl: "./oops-footer.component.html",
  styleUrls: ["./oops-footer.component.scss"]
})
export class FooterComponent {

}
