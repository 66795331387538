import { ErrorHandler, inject } from "@angular/core";
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChildFn } from "@angular/router";
import { UrlCollection } from "../app-routing.module";
import { UserService } from "./user.service";

export const RedirectToRightHomepageGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  const usr = inject(UserService);
  const router = inject(Router);

  const startIndex = window.location.href.indexOf("OOPS");
  const counselorSlotId = startIndex !== -1 ? window.location.href.substring(startIndex) : null;


  if (usr.isAdmin()) {
    if (counselorSlotId || state.url.includes(UrlCollection.reservationOverview)) {
      // Admin, Counselor con counselorSlotId
      return !state.url.includes(UrlCollection.reservationOverview) ? router.navigate([UrlCollection.reservationOverview]) : true;
    } else if (usr.isUser()) {
      // Admin, User (potrebbe essere Counselor ma senza counselorSlotId)
      return !state.url.includes(UrlCollection.user) && !state.url.includes(UrlCollection.admin) ? router.navigate([UrlCollection.user]) : true;
    } else {
      // Admin
      return !state.url.includes(UrlCollection.admin) ? router.navigate([UrlCollection.admin]) : true;
    }
  } else if (usr.isCounselor()) {
    // Counselor
    return !state.url.includes(UrlCollection.counselor) ? router.navigate([UrlCollection.counselor]) : true;
  } else if (usr.isUserAdmin()) {
    // UserAdmin
    return !state.url.includes(UrlCollection.admin) ? router.navigate([UrlCollection.admin]) : true;
  } else if (usr.isUser()) {
    // User
    return !state.url.includes(UrlCollection.user) ? router.navigate([UrlCollection.user]) : true;
  }

  return true;
};

export const CounselorGuard: CanActivateChildFn = async (route: ActivatedRouteSnapshot, { url }: RouterStateSnapshot): Promise<boolean> => {
  const counselorSlotId = new URL(window.location.href.replace("/#/", "/"))
    .searchParams.get("counselorSlotId");

  // In caso tenti di accedere senza aver inserito il parametro obbligatorio counselorSlotId
  if (window.location.href.includes(UrlCollection.localAuthentication) && !counselorSlotId) {
    inject(ErrorHandler).handleError({ httpErrorCode: "400", messageFromError: "counselorSlotId" });
    return false;
  }

  // In caso tenti di accedere a un altra route alla quale non sei autorizzato
  if (!url.includes(UrlCollection.reservationOverview) && !counselorSlotId) {
    inject(ErrorHandler).handleError({ httpErrorCode: "403" });
    return false;
  }

  // Redirect verso la pagina counselor
  if (!url.includes(UrlCollection.reservationOverview)) {
    await inject(Router).navigate([UrlCollection.counselor, UrlCollection.reservationOverview, counselorSlotId]);
  }

  return true;
};
