<div class="content">
  <div class="full-combo header-admin">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <div class="header__home mb-2">
        <div class="header__home-breadcrumbs" *ngIf="adminService.breadcrumbs.length">
          <ng-container *ngFor="let breadcrumb of adminService.breadcrumbs; let last=last">
            <p [class.active]="last" (click)="getBreadcrumb(breadcrumb?.redirectTo)">{{ breadcrumb.pageName | translate }}</p>
            <img *ngIf="!last" src="./assets/icons/chevron-right.svg">
          </ng-container>
        </div>
        <p class="header__home-home">{{ adminService.currentPageName | translate }}</p>
        <p class="header__home-info">{{ adminService.currentPageInfo | translate }}</p>
      </div>

      <div class="header__home" *ngIf="adminService.showDownloadExcel">
        <oops-button label="{{ 'admin.backoffice.EXCEL_TEMPLATE' | translate }}" (onClick)="downloadExcelTemplate()"></oops-button>
      </div>
    </div>
  </div>
</div>

<div class="pages__container">
  <router-outlet></router-outlet>
</div>
