import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";

@Component({
  selector: "oops-pagination",
  templateUrl: "./oops-pagination.component.html",
  styleUrls: ["./oops-pagination.component.scss"]
})
export class OopPaginationComponent implements OnInit, OnChanges {
  @Input() collectionSize!: number;
  @Input() pageSize = 5;
  @Input() pagesToDispay = 5;
  @Input() page = 1;
  @Input() design: "normal" | "input" = "normal";

  @Output() pageChange = new EventEmitter<number>();

  private inputPageTimeout?: ReturnType<typeof setTimeout>;
  private _startFrom = 1;
  protected _pages: number[] = [];

  ngOnInit() {
    this._pages = this.range(
      this.page,
      this.pagesToDispay < this._collectionSize
      ? this.pagesToDispay
      : this._collectionSize
    );
    
    this._startFrom = this.page;
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["pageSize"] || changes["collectionSize"]) {
      this._pages = this.range(this.page,
        this.pagesToDispay < this._collectionSize
        ? this.pagesToDispay
        : this._collectionSize
      );
    }
  }
  
  private range(start: number, end: number): number[] {
    return [...Array(end).keys()].map(e => e + start);
  }

  protected goToPrevPage() {
    if (this.page - 1 == 0) return;

    this.page--;
    const firstPageVisible = this._pages[0];
    if (this.page < firstPageVisible) {
      this.changePagination("prev");
    }
    this.pageChange.emit(this.page);
  }

  protected goToNextPage() {
    if (this.page == this._collectionSize) return;
    this.page++;
    const lastPageVisible = this._pages.slice(-1)[0];
    if (this.page > lastPageVisible) {
      this.changePagination("next");
    }

    this.pageChange.emit(this.page);
  }

  protected goToFirstPage() {
    this.page = 1;
    this._pages = this.range(1, this.pagesToDispay);
  }
  protected goToLastPage() {
    this.page = this._collectionSize;
    this._pages = this.range(this._collectionSize - this.pagesToDispay + 1, this.pagesToDispay);
  }

  protected setCurrentPage(page: number | string, delay?: number) {
    if (this.inputPageTimeout) clearTimeout(this.inputPageTimeout);

    const _page = typeof page == "string" ? parseInt(page) : page;
    this.page = _page;

    if (!delay) this.pageChange.emit(this.page);
    else this.inputPageTimeout = setTimeout(() => this.pageChange.emit(_page || undefined), delay);
  }

  protected get _collectionSize() {
    return Math.ceil(this.collectionSize / this.pageSize);
  }

  private changePagination(direction: "next" | "prev") {
    switch (direction) {
      case "next":
        this._startFrom = this.page + this.pagesToDispay > this._collectionSize ? this._collectionSize - this.pagesToDispay + 1 : this.page;
        this._pages = this.range(this._startFrom, this.pagesToDispay);
        break;
      case "prev":
        this._startFrom = Math.max(this.page - this.pagesToDispay + 1, 1);
        this._pages = this.range(this._startFrom, this.pagesToDispay);
        break;
    }
  }
}
