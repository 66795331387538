import { Injectable } from "@angular/core";
import { OopsBaseModalComponent } from "./oops-base-modal.component";


@Injectable({ providedIn: "root" })
export class OopsBaseModalService {
  private modals: OopsBaseModalComponent[] = [];

  add(modal: OopsBaseModalComponent) {
    if (!modal.id || this.modals.find((x) => x.id === modal.id)) {
      throw new Error("modal must have a unique id attribute");
    }
    // add modal to array of active modals
    this.modals.push(modal);
  }

  remove(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter(x => x.id !== id);
  }

  open(id: string, opt?: { dimension: "lg" | "md" | "sm" | "full" | "auto" }) {
    // open modal specified by id

    const modal = this.modals.find(x => x.id === id);
    modal!.open(opt);
  }

  close(id: string) {
    // close modal specified by id
    const modal = this.modals.find(x => x.id === id);
    modal!.close();
  }
}
