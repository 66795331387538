import { Injectable, inject } from "@angular/core";
import { User } from "oops-cl";
import { BaseDataService } from "src/ancestors/base-data.service";
import { TokenStorageService } from "./token-storage.service";

@Injectable()
export class UserService extends BaseDataService {

  private tkStorage = inject(TokenStorageService);

  constructor() {
    super();
  }

  public get user(): (User) {
    const USER = this.tkStorage.retriveToken(this.tokenKey);
    Object.assign(USER.jwtPayload.user);
    return USER.jwtPayload.user;
  }

  public isUserMyCouselorAccess(): boolean {
    const USER = this.tkStorage.retriveToken(this.tokenKey);
    return USER.jwtPayload?.profiles?.includes("OOPS_MYCOUNSELOR_ACCESS") ?? false;
  }

  public isAdmin(): boolean {
    const USER = this.tkStorage.retriveToken(this.tokenKey);
    return USER.jwtPayload?.profiles?.includes("OOPS_ADMIN") ?? false;
  }

  public isUserAdmin(): boolean {
    const USER = this.tkStorage.retriveToken(this.tokenKey);
    return USER.jwtPayload?.profiles?.includes("OOPS_ADMIN_DATI_DEV") ?? false;
  }

  public isCounselor(): boolean {
    const USER = this.tkStorage.retriveToken(this.tokenKey);
    return USER.jwtPayload?.profiles?.includes("OOPS_COUNSELOR") ?? false;
  }

  public isUser(): boolean {
    const USER = this.tkStorage.retriveToken(this.tokenKey);
    return USER.jwtPayload?.profiles?.includes("OOPS_USER") ?? false;
  }
}
