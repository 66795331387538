<div class="card-wrapper" [class.checked]="checked" (click)="emitClick()">
  <div class="disabled-overlay" *ngIf="disabled"></div>
  <div class="card__action">
    <input type="checkbox" [checked]="checked">
  </div>
  <div class="card__avatar" *ngIf="!counselor?.avatarImage; else AvatarImg">
    <img *ngIf="counselor?.gender == 'F'" src="./assets/icons/avatar-female.svg">
    <img *ngIf="counselor?.gender == 'M'" src="./assets/icons/avatar-male.svg">
  </div>

  <div class="card__info">
    <div class="card__info-role">
      <p>{{ "counselor.card.COUNSELOR" | translate }}</p>
    </div>
    <div class="card__info-anag">
      <p>{{ counselor?.forename }} {{ counselor?.surname}}</p>
    </div>
    <div class="card__info-descr">
      <p>{{ counselor?.description | truncate: 100}}</p>
    </div>
  </div>
  <div class="d1-container">
    <p class="show-details" (click)="emitClick(true)">{{'counselor.card.SHOW_DETAILS' | translate}}</p>
  </div>
</div>

<ng-template #AvatarImg>
  <div class="card__avatar">
    <img [src]="counselor?.avatarImage">
  </div>
</ng-template>