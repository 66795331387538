<button (click)="skipToContent()" class="skip-link">Salta al contenuto principale</button>

<header id="header-content">
  <oops-header *ngIf="appService.showHeader" [items]="drawerItems" (onRedirection)="onNavTabClick($event)"
    (onOpen)="loadMenuOptions()">
  </oops-header>
</header>

<main id="main-content" tabindex="-1">
  <router-outlet (activate)="appService.setHeaderVisibility(); appService.setFooterVisibility()"></router-outlet>
</main>

<footer id="footer-content">
  <oops-footer *ngIf="appService.showFooter"></oops-footer>
</footer>
<oops-toast [hideAfter]="1000"></oops-toast>