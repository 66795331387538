import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent } from "src/ancestors/base/base.component";
import { UrlCollection } from "src/app/app-routing.module";
import { DrawerItems } from "../oops-drawer/oops-drawer.component";


@Component({
  selector: "oops-header",
  templateUrl: "./oops-header.component.html",
  styleUrls: ["./oops-header.component.scss"]
})
export class HeaderComponent extends BaseComponent {
  @Input() items?: DrawerItems[] = [];
  @Input() showMenu?: boolean;
  @Output() onRedirection = new EventEmitter<DrawerItems>();
  @Output() onOpen = new EventEmitter<void>();

  protected async onLogoClicked() {
    await this.router.navigate([UrlCollection.user]);
  }

  protected onToggleMenu() {
    this.showMenu = !this.showMenu;
    this.onOpen.emit();
  }
}
