<div class="main-container" role="listitem">
  <!-- Immagine card -->
  <img aria-hidden="true" [src]="src">

  <div class="d1-container">
    <div class="text-container">
      <!-- Aggionamento data -->
      <p class="date-text" *ngIf="date">{{('card.LAST_UPDATE' | translate) + ' ' + (date | date: 'dd/MM/yyyy')}}</p>
      <!-- Titolo -->
      <h5 class="title" *ngIf="title">{{title}}</h5>
      <!-- Descrizione -->
      <p class="subtitle" *ngIf="subtitle">{{subtitle}}</p>
    </div>

    <!-- Pulsante -->
    <button class="button-container" (click)="onClicked()">
      <p class="text">{{'generic.enter' | translate}}</p>
      <img aria-hidden="true" src="./assets/icons/chevron-right-red.svg">
    </button>
  </div>
</div>