import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Counselor } from "oops-cl";

@Component({
  selector: "oops-counselor-card",
  templateUrl: "./oops-counselor-card.component.html",
  styleUrls: ["./oops-counselor-card.component.scss"]
})
export class OopsCounselorCardComponent {
  @Input() checked?: boolean;
  @Input() counselor?: Counselor;
  @Input() disabled?: boolean;


  @Output() onClick = new EventEmitter<Counselor>();
  @Output() onInfoClick = new EventEmitter<Counselor>();

  protected showDetails = false;

  emitClick(openDetails?: boolean) {
    if (openDetails) {
      this.checked = !this.checked;
      this.onInfoClick.emit(this.counselor);
    }
    this.onClick.emit(this.counselor);
  }
}
