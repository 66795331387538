import { Injectable } from "@angular/core";
import { BaseDataService } from "src/ancestors/base-data.service";

export interface Breadcrum {
  pageName: string;
  redirectTo?: string;
}
@Injectable()
export class BannerService extends BaseDataService {
  public _bannerBreadCumb: Breadcrum[] = [];
  public _bannerPageName?: string;
  public _showBanner = false;

  public set bannerBreadCumb(value: Breadcrum[]) {
    this._bannerBreadCumb = value;
  }
  public get bannerBreadCumb() {
    return this._bannerBreadCumb;
  }

  public set bannerPageName(value: string) {
    this._bannerPageName = value;
  }
  public get bannerPageName() {
    return this._bannerPageName ?? "";
  }

  public get showBanner() {
    return this._showBanner;
  }

  public set showBanner(value: boolean) {
    this._showBanner = value;
  }
}