<div
  (click)="onClickEvent()"
  [className]="dimension"
  [ngClass]="{
    'txt-bold': textWeight == 'bold',
    'txt-light': textWeight == 'light',
    'full': design == 'full',
    'empty': design == 'empty',
    'ghost': design == 'ghost',
    'disabled': disabled
  }">
  <img class="left" *ngIf="src && imgPlacement == 'left'" [src]="src">
  <img class="left" *ngIf="src && !imgPlacement" [src]="src">
  <p [innerHTML]="label"></p>
  <img class="right" *ngIf="src && imgPlacement == 'right'" [src]="src">
</div>