import { Component, inject } from "@angular/core";
import { BaseComponent } from "src/ancestors/base/base.component";
import { AdminService } from "./admin.service";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
  providers: [AdminService]
})
export class AdminComponent extends BaseComponent {
  protected adminService = inject(AdminService);

  constructor() {
    super();
    this.logger.setCaller("AdminComponent");
    this.adminService.showBanner = false;
  }

  protected async getBreadcrumb(redirectTo?: string) {
    if (redirectTo) await this.router.navigate([redirectTo]);
  }

  protected downloadExcelTemplate() {
    window.open("assets/doc/OOPS - Caricamento slot counselor.xlsx", "_self");
  }
}
