import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "oops-item-home",
  templateUrl: "./oops-item-home.component.html",
  styleUrls: ["./oops-item-home.component.scss"]
})
export class OopsItemHomeComponent {
  @Input() date?: Date;
  @Input() title = "";
  @Input() subtitle = "";
  @Input() src = "";

  @Output() onClick = new EventEmitter<void>();

  onClicked() {
    this.onClick.emit();
  }
}