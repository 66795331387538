import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "oops-button",
  templateUrl: "./oops-button.component.html",
  styleUrls: ["./oops-button.component.scss"]
})
export class OopsButtonComponent {
  @Input() label = "Inserisci una label";
  @Input() textWeight: "bold" | "light" = "bold";
  @Input() design: "full" | "empty" | "ghost" = "full";
  @Input() src?: string;
  @Input() imgPlacement: "left" | "right" = "left";
  @Input() dimension: "lg" | "md" | "sm" = "md";
  @Input() disabled?: boolean;


  @Output() onClick = new EventEmitter<void>();

  protected onClickEvent() {
    if (!this.disabled) this.onClick.emit();
  }
}
