import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from "@angular/core";

@Component({
  selector: "oops-checkbox",
  templateUrl: "./oops-checkbox.component.html",
  styleUrls: ["./oops-checkbox.component.scss"]
})
export class OopsCheckboxComponent {
  @Input() checked = false;
  @Input() disabled?: boolean;
  @Input() readonly = false;
  @Input() label?: string;
  @Output() onCheck: EventEmitter<any> = new EventEmitter();

  @ContentChild("customLabel", { static: false }) customLabel?: TemplateRef<any>;

  constructor() { }

  ngOnInit() { }

  emitOnCheck() {
    if (!this.disabled) {
      this.checked = !this.checked;
      this.onCheck.emit(this.checked);
    }
  }
}
