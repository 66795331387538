<div class="box-preview">
  <p class="title">{{title}}</p>

  <div class="preview-container">
    <div
      *ngIf="uploadService.file;
      else NotYetUploaded"
      (drop)="handleFileInputOnDrag($event)"
      (dragover)="allowDrag($event)"
      class="drag-and-drop">
      <img class="big-size" src="./assets/icons/xls.svg">
      <div class="file-upload-container">
        <p class="text-big">{{ uploadService.file.name }}</p>
        <p class="text-small">file - {{ uploadService.file.size }}kB</p>
        <a (click)="uploadDoc()">
          <h4 class="upload-link">
            {{'uploadFile.UPDATE_FILE' | translate}}
          </h4>
        </a>
      </div>
    </div>

    <ng-template #NotYetUploaded>
      <div class="drag-and-drop"
        (drop)="handleFileInputOnDrag($event)" (dragover)="allowDrag($event)">
        <img class="big-size" src="./assets/icons/upload-cloud.svg">
        <div class="file-upload-container">
          <p class="title-section">{{'uploadFile.DRAG_FILE' | translate}}</p>
          <p class="text-small">{{'uploadFile.OR' | translate}}</p>
          <a (click)="uploadDoc()">
            <h4 class="upload-link">
              {{'uploadFile.CHOOSE_FILES' | translate}}
            </h4>
          </a>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<div>
  <input type="file" id="uploadFile" (change)="handleFileInput($event)" [accept]="acceptedMimeTypes">
</div>