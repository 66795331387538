import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "timezone"
})
export class TimezonePipe implements PipeTransform {

  transform(value?: string | Date, timeZone?: string): string {
    const date = typeof value === "string" ? new Date(value) : value;
    const options: Intl.DateTimeFormatOptions = {
      timeZone,
      day: "2-digit",
      month: "long",
      year: "numeric"
    };
    return new Intl.DateTimeFormat("it-IT", options).format(date);
  }
}