import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { ControlValueAccessor, NgControl, ValidationErrors, Validators } from "@angular/forms";

@Component({
  selector: "oops-input",
  templateUrl: "./oops-input.component.html",
  styleUrls: ["./oops-input.component.scss"]
})
export class OOPSInputComponent implements ControlValueAccessor, AfterViewInit {
  private ngControl = inject(NgControl, { optional: true });
  private cdr = inject(ChangeDetectorRef);

  constructor() {
    this.ngControl ? this.ngControl.valueAccessor = this : undefined;
  }
  @Input() type?: string = "text";
  @Input() placeholder?: string = "text";
  @Input() customIconStart?: boolean;
  @Input() src?: string;
  @Input() customLabel?: boolean;
  @Input() clearable?: boolean;
  @Input() searchIcon?: boolean;
  @Input() boxedIcon?: boolean;
  @Input() required?: boolean;
  @Input() disabled?: boolean;
  @Input() readOnly?: boolean;
  @Input() autoHeight?: boolean;
  @Input() textAlign: "left" | "center" | "right" = "left";
  @Input() max?: number;
  @Input() min?: number;
  @Input() placement: "start" | "end" = "end";
  @Input() design: "rounded" | "normal" = "normal";

  @Output() onChange = new EventEmitter<string>();
  @Output() onClear = new EventEmitter<void>();
  @Output() onClick = new EventEmitter<string>();
  @Output() onEnter = new EventEmitter<string>();
  @Output() onFocus = new EventEmitter<void>();

  protected _required?: boolean;
  private _value?: string;
  protected _disabled = false;
  protected _displayError?: ValidationErrors | null;
  private onTouched = () => { };
  private onChanged = (_: any) => { };

  ngAfterViewInit(): void {
    // Setta l'obbligatorietà del componente per visualizzare *
    this._required = this.ngControl?.control?.hasValidator(Validators.required);
    this.cdr.detectChanges();
  }
  checkForError() {
    if (!this.ngControl) return;
    const { errors, dirty, touched } = this.ngControl;
    if (this.ngControl.errors && (dirty || touched)) {
      this._displayError = errors;
      return true;
    }
    return null;
  }

  get value() {
    return this._value ?? "";
  }
  set value(value: string) {
    this._value = value;
    this.onChanged(value);
  }
  onBlur() {
    this.onTouched();
  }
  writeValue(obj: string): void {
    this._value = obj;
    if (this.max) {
      const valueParsed = typeof obj == "number" ? obj : parseInt(obj);
      if (valueParsed > this.max) {
        this._value = this.max.toString();
      }
    }
    if (this.min) {
      const valueParsed = typeof obj == "number" ? obj : parseInt(obj);
      if (valueParsed < this.min) {
        this._value = this.min.toString();
      }
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }
}