import { Component, EventEmitter, Input, Output } from "@angular/core";
import { OptionRes } from "oops-cl";

@Component({
  selector: "oops-select",
  templateUrl: "./oops-select.component.html",
  styleUrls: ["./oops-select.component.scss"]
})
export class OopsSelectComponent {

  constructor() {

  }
  @Input() customLabel?: boolean;
  @Input() disabled?: boolean;
  @Input() items!: OptionRes[];
  @Input() defaultValue!: number | null;
  @Output() selectedValue = new EventEmitter<string>();

  changeValue(value: Event | null | undefined) {
    if (value?.target) {
      this.selectedValue.emit((value.target as HTMLInputElement).value);
    }
  }
}
