import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "oops-modal",
  templateUrl: "./oops-modal.component.html",
  styleUrls: ["./oops-modal.component.scss"]
})
export class OopsModalComponent {
  @Input() id!: string;
  @Input() title?: string;
  @Input() mandatory?: boolean;
  @Input() confirmButton?: string;
  @Input() cancelButton?: string;
  @Input() confirmDisabled?: boolean;

  @Output() onClose = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<void>();
}
