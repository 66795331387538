import { Component, inject } from "@angular/core";
import { BaseComponent } from "src/ancestors/base/base.component";
import { BannerService } from "src/app/pages/user/banner-data.service";
import { UserDataService } from "./user-data.service";
import { UrlCollection } from "src/app/app-routing.module";


@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
  providers: [BannerService, UserDataService]

})
export class UserComponent extends BaseComponent {
  constructor() {
    super();
    this.logger.setCaller("UserComponent");
  }
  protected playerVisible = false;
  protected bannerService = inject(BannerService);
  protected kScript = this.env.kalturaScript;
  protected isHomepage?: boolean;

  protected goToOopsPage() {
    this.playerVisible = true;
    this.modalService.open("oops-tutorial", { dimension: "md" });
  }

  protected redirectToHomepage(redirectTo?: string) {
    return this.router.navigate([redirectTo], { relativeTo: this.route });
  }

  protected onModalClose() {
    this.modalService.close("oops-tutorial");
    this.playerVisible = false;
  }

  protected onRouteChange(route: string) {
    if (route.includes(UrlCollection.userHomepage)) {
      this.isHomepage = true;
      this.bannerService.bannerPageName = this.translateService.instant("generic.HOME", { transform: "capitalize" });
    } else {
      this.isHomepage = false;
      this.bannerService.bannerPageName = this.translateService.instant("page.OOPS");
    }
  }

  protected navigationBack() {
    
    history.back();
  }
}
