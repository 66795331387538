import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "oops-link-box",
  templateUrl: "./oops-link-box.component.html",
  styleUrls: ["./oops-link-box.component.scss"]
})
export class OopsLinkBoxComponent {

  @Input() title?: string = "";
  @Input() linkText?: string = "";
  @Input() src?: string = "";
  @Input() disabled?: boolean;
  @Output() onButtonClicked: EventEmitter<any> = new EventEmitter();


  onClicked() {
    this.onButtonClicked.emit();
  }
}
