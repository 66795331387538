import { Component, EventEmitter, Input, Output } from "@angular/core";
export interface RadioItem {
  id: number,
  value: string
}
@Component({
  selector: "oops-radio",
  templateUrl: "./oops-radio.component.html",
  styleUrls: ["./oops-radio.component.scss"]
})
export class OopsRadioComponent {

  @Input() items: RadioItem[] = [];
  @Input() disabled = false;
  @Input() bindValue?: RadioItem = { id: 0, value: "" };
  @Input() withLabel = true;
  @Input() switch = false;
  @Input() readonly = false;
  @Output() onItemChange: EventEmitter<any> = new EventEmitter();

  ngOninit() {
  }
  emitOnItemChange(item: RadioItem, index?: number) {
    this.bindValue = item;
    if (index != undefined && index >= 0) {
      this.onItemChange.emit({
        id: index,
        value: this.bindValue
      });
    } else {
      this.onItemChange.emit(this.bindValue);
    }
  }

  setValue(event: RadioItem) {
    this.emitOnItemChange(event);
  }
}
