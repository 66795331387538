import { Injectable, inject } from "@angular/core";

import { OOPSLoginInfo } from "oops-cl";
import { EnvironmentLoaderService } from "src/ancestors/env-config.service";

@Injectable()
export class TokenStorageService {
  private env = inject(EnvironmentLoaderService);
  constructor() { }
  /**
   * 
   * @returns token salvato + data scadenza
   */
  public retriveToken(tokenKey: string): OOPSLoginInfo {
    let token: string | null | undefined;
    const { storeAccessToken } = this.env.getEnvConfig();
    
    switch (storeAccessToken) {
      case "sessionstorage": {
        token = sessionStorage.getItem(tokenKey);
        break;
      }
    
      default: {
        token = document.cookie.split(";")
          ?.find((item) => item?.trim()?.startsWith(`${tokenKey}=`))
          ?.split(`${tokenKey}=`)[1];
        break;
      }
    }
    
      if (token) {
      return JSON.parse(token) as OOPSLoginInfo;
    }

    return {} as OOPSLoginInfo;
  }

  /**
   * @param token
   * Salva il token nello storage
   */
  public saveToken(tokenKey: string, token: OOPSLoginInfo): void {
    const { storeAccessToken } = this.env.getEnvConfig();
    
    switch (storeAccessToken) {
      case "sessionstorage": {
        sessionStorage.setItem(tokenKey, JSON.stringify(token));
        break;
      }
    
      default: {
        document.cookie = `${tokenKey}=${JSON.stringify(token)}; samesite=strict; path=/`;
        break;
      }
    }
  }

  /**
   * 
   * Elimina il token dallo storage
   */
  public deleteToken(tokenKey: string): void {
    const { storeAccessToken } = this.env.getEnvConfig();
    
    switch (storeAccessToken) {
      case "sessionstorage": {
        sessionStorage.removeItem(tokenKey);
        break;
      }
    
      default: {
        document.cookie = `${tokenKey}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
        break;
      }
    }
  }
}



// export type JSONValue = string | number | boolean | { [x: string]: JSONValue } | JSONValue[];
