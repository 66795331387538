<oops-base-modal [id]="id" [mandatory]="mandatory">
  <div class="oops-modal">

    <div class="modal__close" (click)="onClose.emit()">
      <img src="./assets/icons/close.svg">
    </div>

    <div class="modal__header">
      <div class="modal__header-title">
        <p>{{ title }}</p>
      </div>
    </div>

    <ng-content></ng-content>

    <div class="modal__actions">
      <div class="modal__actions-cancel" *ngIf="cancelButton">
        <oops-button design="empty" [label]="cancelButton" (onClick)="this.onCancel.emit()"></oops-button>
      </div>
      <div class="modal__actions-confirm" *ngIf="confirmButton">
        <oops-button [disabled]="confirmDisabled" design="full" textWeight="light" [label]="confirmButton"
          (onClick)="this.onConfirm.emit()"></oops-button>
      </div>
    </div>
  </div>
</oops-base-modal>