import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslatePipe } from "./pipe/translation.filter.pipe";
import { LoaderDirective } from "./directive/loader.directive";
import { HeaderComponent } from "./components/oops-header/oops-header.component";
import { FooterComponent } from "./components/oops-footer/oops-footer.component";
import { OOPSInputComponent } from "./components/oops-input/oops-input.component";
import { OopsLinkBoxComponent } from "./components/oops-link-box/oops-link-box.component";
import { OopsCounselorCardComponent } from "./components/oops-counselor-card/oops-counselor-card.component";
import { OopPaginationComponent } from "./components/oops-pagination/oops-pagination.component";
import { OopsSlotComponent } from "./components/oops-slot/oops-slot.component";
import { OopsRadioComponent } from "./components/oops-radio/oops-radio.component";
import { OopsButtonComponent } from "./components/oops-button/oops-button.component";
import { OopsBaseModalComponent } from "./components/oops-base-modal/oops-base-modal.component";
import { OopsModalComponent } from "./components/oops-modal/oops-modal.component";
import { TableOptionsDirective } from "./directive/table-options.directive";
import { OopsTableComponent } from "./components/oops-table/oops-table.component";
import { OopsTableDataComponent } from "./components/ops-table-data/oops-table-data.component";
import { OopsTableHeadComponent } from "./components/ops-table-head/oops-table-head.component";
import { OopsCheckboxComponent } from "./components/oops-checkbox/oops-checkbox.component";
import { OopsUploadComponent } from "./components/oops-upload/oops-upload.component";
import { OopsDrawerComponent } from "./components/oops-drawer/oops-drawer.component";
import { OopsToastComponent } from "./components/oops-toast/oops-toast.component";
import { RemoveAfterDirective } from "./directive/remove-after.directive";
import { UploadService } from "./components/oops-upload/upload-data.service";
import { KalturaPlayerComponent } from "./components/kaltura-player/kaltura-player.component";
import { OopsLoaderComponent } from "./components/oops-loader/oops-loader.component";
import { OopsSelectComponent } from "./components/oops-select/oops-select.component";
import { OopsItemHomeComponent } from "./components/oops-item-home/oops-item-home.component";
import { TruncatePipe } from "./pipe/truncate.pipe";
import { TimezonePipe } from "./pipe/timezone.pipe";



@NgModule({
  declarations: [
    TranslatePipe,
    TruncatePipe,
    TimezonePipe,
    LoaderDirective,
    RemoveAfterDirective,
    HeaderComponent,
    FooterComponent,
    OOPSInputComponent,
    OopsLinkBoxComponent,
    OopsCounselorCardComponent,
    OopPaginationComponent,
    OopsSlotComponent,
    OopsRadioComponent,
    OopsButtonComponent,
    OopsBaseModalComponent,
    OopsModalComponent,
    OopsTableComponent,
    OopsTableDataComponent,
    OopsTableHeadComponent,
    OopsCheckboxComponent,
    OopsUploadComponent,
    OopsDrawerComponent,
    OopsToastComponent,
    KalturaPlayerComponent,
    OopsLoaderComponent,
    OopsSelectComponent,
    OopsItemHomeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    RouterModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    TableOptionsDirective
  ],
  exports: [
    TranslatePipe,
    TruncatePipe,
    TimezonePipe,
    LoaderDirective,
    RemoveAfterDirective,
    HeaderComponent,
    FooterComponent,
    OOPSInputComponent,
    OopsLinkBoxComponent,
    OopsCounselorCardComponent,
    OopPaginationComponent,
    OopsSlotComponent,
    OopsRadioComponent,
    OopsButtonComponent,
    OopsBaseModalComponent,
    OopsModalComponent,
    TableOptionsDirective,
    OopsTableComponent,
    OopsTableDataComponent,
    OopsTableHeadComponent,
    OopsCheckboxComponent,
    OopsUploadComponent,
    OopsDrawerComponent,
    OopsToastComponent,
    KalturaPlayerComponent,
    OopsLoaderComponent,
    OopsSelectComponent,
    OopsItemHomeComponent
  ],
  providers: [UploadService]
})
export class SharedModule { }
