import { Component, Input } from "@angular/core";

@Component({
  selector: "oops-loader",
  templateUrl: "./oops-loader.component.html",
  styleUrls: ["./oops-loader.component.scss"]
})
export class OopsLoaderComponent {
  @Input() width?: string;
  @Input() marginBlock?: string;
}
