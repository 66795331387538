<div class="banner__container full-combo" *ngIf="bannerService.showBanner">
  <!-- d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between -->
  <div class="d-flex justify-content-between">
    <div class="banner__info" [class.counselor]="usrService.isCounselor()">
      <div class="banner__info-breadcrums" *ngIf="usrService.isUser() && !isHomepage">
        <div class="banner__action">
          <!-- <div class="banner__action-back" (click)="navigationBack()">
            <img alt="" src="./assets/icons/chevron-right-red.svg">
            <p>{{ "generic.BACK" | translate }}</p>
          </div> -->
        </div>
      </div>
      <div class="banner__info-page-name" [class.is-homepage]="isHomepage">
        <p>{{ bannerService.bannerPageName }}</p>
      </div>
    </div>
    <div class="banner__action d-none d-sm-block">
      <div class="banner__action-tutorial" (click)="goToOopsPage()">
        <img alt="" src="./assets/icons/play-icon.svg">
        <p>{{ "banner.tutorial" | translate }}</p>
      </div>
    </div>
  </div>
</div>
<div  class="pages-container">
  <router-outlet (activate)="onRouteChange($event.router.url)"></router-outlet>
</div>

<oops-modal id="oops-tutorial" (onClose)="onModalClose()" [mandatory]="true">
  <div class="modal__content w-100">
    <ng-container *ngIf="playerVisible">
      <kaltura-player customId="introKalturaVideo" [kalturaScript]="kScript">
      </kaltura-player>
    </ng-container>
  </div>
</oops-modal>