import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TokenManagerService } from "./token-manager.service";
import { User } from "oops-cl";
import { UrlCollection } from "../app-routing.module";

export namespace DoctorWizardStatusList {
  export const progress = "PROGRESS";
  export const stop = null;
}

@Injectable()
export class ApplicationDataService {
  private _tkManager: TokenManagerService = inject(TokenManagerService);
  private router: Router = inject(Router);

  private _showHeader = false;
  private _showFooter = false;

  public stringify(param?: unknown): string  | undefined {
    if (!param) return undefined;

    return typeof param === "string" ? param : JSON.stringify(param);
  }

  /**
   * Utilizzando la pipe keyValue l'ordine con cui i dati vengono esposti non corrisponde all'ordine originale nella quale il parametro si trova all'interno dell'oggezzo.
   *
   * obj = { z: 1, c: 2, l: 3 } ==> 2, 3, 1
   *
   * Se si desidera mantenere l'ordine originale di inserimento passare come parametro alla pipe questo metodo.
   *
   *
   * {{ loop | keyValue: originalOrder }}
   *
   * @param a
   * @param b
   * @returns
   */
  public originalOrder = (a: unknown, b: unknown): number => {
    return 0;
  };

  /**
   * Scorre la pagina verso il punto specificato.
   * 
   * @param ancorTagId L'ID dell'ancora a cui si desidera scorrere la pagina.
   */
  public scrollTo(ancorTagId: string) {
    const ancor = document.querySelector(ancorTagId);
    const position = ancor?.getBoundingClientRect();
    if (position) {
      window.scrollTo(0, document.body.scrollHeight - position.height);
    }
  }

  public isEmpty(obj: unknown): obj is undefined {
    if (typeof obj === "string") {
      return obj === "" ? true : false;
    }

    if (obj === undefined || obj === null) {
      return true;
    }

    if (JSON.stringify(obj) === "{}") {
      return true;
    }

    if (JSON.stringify(obj) === "[]") {
      return true;
    }

    return false;
  }
  public setHeaderVisibility() {
    if (this.router.url.includes(UrlCollection.localAuthentication) || this.router.url.includes(UrlCollection.passwordRecovery) || this.router.url.includes(UrlCollection.samlSso)) {
      this._showHeader = false;
    } else {
      this._showHeader = true;
    }
  }

  public get showHeader(): boolean {
    return this._showHeader;
  }
  public setFooterVisibility() {
    if (this.router.url.includes(UrlCollection.localAuthentication) || this.router.url.includes(UrlCollection.passwordRecovery) || this.router.url.includes(UrlCollection.samlSso)) {
      this._showFooter = false;
    } else {
      this._showFooter = true;
    }
  }
  public get showFooter() {
    return this._showFooter;
  }
}
