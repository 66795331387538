import { ErrorHandler, inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationsUrls, Counselor, CounselorSlot, OOPSProcess, CurrentProcessAcknowledges, OOPSLoginInfo, CounselorSlotImportResult, UtentiAnagraficaResponse, GroupRes, RisultatoListaUtenti } from "oops-cl";

import { API_REQUEST_METHODS, HttpService } from "src/ancestors/base-api.service";
import { TokenStorageService } from "src/app/global-service/token-storage.service";
import { ExtendedUtentiAnagraficaResponse, UserBreadcrumbsResponse } from "../pages/admin/db-data/user-detail/personal-data/personal-data.component";
import { PerformanceData } from "../pages/admin/db-data/user-detail/performance-management/performance-management.component";
import { ActiveGroup } from "../pages/admin/db-data/user-detail/development/development.component";

export class RisultatoListaUtentiEstesa {
  users: ExtendedRisultatoListaUtenti[] = [];
  totalRecords!: number;
}

interface ExtendedRisultatoListaUtenti extends RisultatoListaUtenti {
  Società_Appartenenza?: string;
}

export interface UserParams {
  email: string;
  password: string;
  langCode?: string;
  deviceType?: string;
  userAgent?: string;
  createPersistentUserAuth?: string;
}

@Injectable()
export class BackendService extends HttpService {
  constructor() {
    super();
    this.logger.setCaller("BackendService");
    this.createInstance({
      url: this.env.apiBaseUrl,
      preventFastFlickering: true,
      minimum: 1500
    });
  }

  private tkStorage: TokenStorageService = inject(TokenStorageService);
  private router: Router = inject(Router);
  private errorHandler = inject(ErrorHandler);

  /** AUTH */

  /**
   * @param loginParam parametri per eseguire il login.
   */
  async localLogin(loginParam: UserParams): Promise<OOPSLoginInfo | undefined> {
    try {
      const loginRes = await this.httpClient<OOPSLoginInfo>("authn/login", {
        method: API_REQUEST_METHODS.POST,
        body: loginParam
      });


      if (loginRes) {
        return loginRes;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  /**
   * Rinnova il token di autenticazione ed invalida il precedente.
   */
  async refreshToken(): Promise<OOPSLoginInfo | undefined> {
    try {
      const refreshToken = await this.httpClient<OOPSLoginInfo>("authn/refreshToken", {
        method: API_REQUEST_METHODS.POST
      });

      if (refreshToken) {
        return refreshToken;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  async decodeSingleUseToken(singleUseToken: string): Promise<OOPSLoginInfo | undefined> {
    try {
      const decodeSingleUseToken = await this.httpClient<OOPSLoginInfo>("authn/decodeSingleUseToken", {
        query: { singleUseToken }
      });

      if (decodeSingleUseToken) {
        return decodeSingleUseToken;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  async createSingleUseToken(): Promise<string | undefined> {
    try {
      const createSingleUseToken = await this.httpClient<OOPSLoginInfo>("/authn/createSingleUseToken", {
        method: "post"
      });

      if (createSingleUseToken) {
        return createSingleUseToken.token;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  /**
   * @returns Invalida il token dell'utente e ne impedisce l'uso e il rinnovo.
   */
  public async logout(redirectToLogin?: string): Promise<boolean> {
    try {
      const logoutRes = await this.httpClient<OOPSLoginInfo>("authn/logout", {
        method: API_REQUEST_METHODS.POST
      });

      if (logoutRes) {
        this.tkStorage.deleteToken(this.env.localTokenKey);
        await this.router.navigate([redirectToLogin ? redirectToLogin : this.env.loginUrl]);
        return true;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  async initPasswordRecovery(recoverParam: { email: string }): Promise<boolean> {
    try {
      const recoverRes = await this.httpClient<boolean>("authn/counselor/initPasswordRecovery", {
        method: API_REQUEST_METHODS.POST,
        body: {
          email: recoverParam.email
        }
      });


      if (recoverRes) {
        return recoverRes;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  async completePasswordRecovery(setPassword: { userId: string, validationToken: string, newPassword: string }): Promise<boolean> {
    try {
      const setPasswordRes = await this.httpClient<boolean>("authn/counselor/completePasswordRecovery", {
        method: API_REQUEST_METHODS.POST,
        body: {
          userId: setPassword.userId,
          validationToken: setPassword.validationToken,
          newPassword: setPassword.newPassword
        }
      });


      if (setPasswordRes) {
        return setPasswordRes;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }


  public async getProcessAcknowledges(): Promise<CurrentProcessAcknowledges | undefined> {
    try {
      const processesAcknowLedgesRes = await this.httpClient<CurrentProcessAcknowledges>("processAcknowledges/get");

      if (processesAcknowLedgesRes) {
        return processesAcknowLedgesRes;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async getApplicationsUrls(): Promise<ApplicationsUrls | undefined> {
    try {
      const applicationsUrlsRes = await this.httpClient<ApplicationsUrls>("processAcknowledges/getApplicationsUrls");

      if (applicationsUrlsRes) {
        return applicationsUrlsRes;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }
  public async createATFRetrieveTokenAfterLogin(): Promise<string | undefined> {
    try {
      const tokenAfterLogin = await this.httpClient<string>("authn/user/createATFRetrieveTokenAfterLogin", {
        method: API_REQUEST_METHODS.POST
      });

      if (tokenAfterLogin) {
        return tokenAfterLogin;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;

  }

  async setProcessAcknowledges(processAcknowledges: CurrentProcessAcknowledges): Promise<CurrentProcessAcknowledges | undefined> {
    try {
      const processesAcknowLedgesRes = await this.httpClient<CurrentProcessAcknowledges>("processAcknowledges/set", {
        method: API_REQUEST_METHODS.POST,
        body: { acks: processAcknowledges }
      });


      if (processesAcknowLedgesRes) {
        return processesAcknowLedgesRes;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  /**
   * Conta i processi per cui è disponibile la reportistica
   *
   * @returns number
   */
  public async adminProcessesCount() {
    try {
      const countProcessesRes = await this.httpClient<number>("export/countProcesses");

      if (countProcessesRes >= 0) {
        return countProcessesRes;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return 0;
  }

  /**
   * Recupera la lista dei processi per cui è disponibile la reportistica
   *
   * @param params
   * @returns OOPSProcess[]
   */
  public async adminProcessesList(fromRecord: number, numRecords: number) {
    try {
      const listProcessesRes = await this.httpClient<OOPSProcess[]>("export/listProcesses", {
        query: { fromRecord, numRecords },
        preventFastFlickering: true
      });

      if (listProcessesRes) {
        return listProcessesRes;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  public async exportAdminCompletionReport(year: number) {
    try {
      const fileName = await this.httpClient<string>("export/admin/completionReport", {
        query: { year }
      });

      if (fileName) {
        return fileName;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public fileDownload(fileName: string, authToken: string) {
    const path = this.createPath(this.env.apiBaseUrl, "/export/downloadFile", { fileName, authToken });
    window.open(path, "_self");
  }

  public async massiveCounselorSlotsImport(file: FormData) {
    try {
      const fileName = await this.httpClient<CounselorSlotImportResult>("counselorSlot/admin/import", {
        method: "post",
        body: file,
        formData: true,
        preventFastFlickering: true
      });

      if (fileName) {
        return fileName;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }

  public async massiveCounselorSlotsRemove(file: FormData) {
    try {
      const fileName = await this.httpClient<CounselorSlotImportResult>("counselorSlot/admin/remove", {
        method: "delete",
        body: file,
        formData: true,
        preventFastFlickering: true
      });

      if (fileName) {
        return fileName;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }

  public async counselorCancelAppointment(counselorSlotId: string) {
    try {
      const appointmentIsDeleted = await this.httpClient<boolean>("/counselorSlot/counselor/cancelAppointment", {
        method: "delete",
        path: [counselorSlotId]
      });

      if (appointmentIsDeleted) {
        return appointmentIsDeleted;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }


  public async adminCancelAppointment(counselorSlotId: string) {
    try {
      const appointmentIsDeleted = await this.httpClient<boolean>("/counselorSlot/admin/cancelAppointment", {
        method: "delete",
        path: [counselorSlotId]
      });

      if (appointmentIsDeleted) {
        return appointmentIsDeleted;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  public async userCancelAppointment(counselorSlotId: string) {
    try {
      const appointmentIsDeleted = await this.httpClient<boolean>("/counselorSlot/user/cancelAppointment", {
        method: "delete",
        path: [counselorSlotId]
      });

      if (appointmentIsDeleted) {
        return appointmentIsDeleted;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  /**
   * Restituisce i counselor selezionabili dall'utente in fase di prenotazione appuntamento OOPS
   *
   * @param searchedText
   * @param fromRecord
   * @param numRecords
   * @returns Counselor[]
   */
  public async counselorList(searchedText?: string, fromRecord?: number, numRecords?: number) {
    try {
      const counselorList = await this.httpClient<Counselor[]>("/counselor/search", {
        query: { searchedText, fromRecord, numRecords },
        preventFastFlickering: true
      });

      if (counselorList.length) {
        return counselorList;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  /**
   * Conta i counselor selezionabili dall'utente in fase di prenotazione appuntamento OOPS
   *
   * @param searchedText
   * @returns number
   */
  public async counselorCount(searchedText?: string) {
    try {
      const counselorCount = await this.httpClient<number>("/counselor/count", {
        query: { searchedText }
      });

      if (counselorCount >= 0) {
        return counselorCount;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return 0;
  }

  /**
   * Recupera gli slot orari dei counselor selezionabili da un utente e per cui l'utente loggato si può prenotare
   *
   * @param counselorId
   * @param fromRecord
   * @param numRecords
   * @returns CounselorSlot[]
   */
  public async counselorSlotList(counselorId: string, fromRecord?: number, numRecords?: number) {
    try {
      const counselorSlotList = await this.httpClient<CounselorSlot[]>("/counselorSlot/user/list", {
        path: [counselorId],
        query: { fromRecord, numRecords },
        preventFastFlickering: true
      });

      if (counselorSlotList.length) {
        return counselorSlotList;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  /**
   * Conta gli slot dei counselor selezionabili da un utente
   *
   * @returns number
   */
  public async counselorSlotCount(counselorId: string) {
    try {
      const counselorSlotCount = await this.httpClient<number>("/counselorSlot/user/count", {
        path: [counselorId]
      });

      if (counselorSlotCount >= 0) {
        return counselorSlotCount;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return 0;
  }

  /**
   * Effettua la prenotazione di un appuntamento, in un determinato slot, da parte di un utente.
   * La prenotazione sarà possibile solo per gli slot che sono in status AVAILABLE.
   *
   * @param counselorSlotId
   * @returns
   */
  public async userBookAppointment(counselorSlotId: string) {
    try {
      const isBooked = await this.httpClient<boolean>("/counselorSlot/user/bookAppointment", {
        method: "post",
        body: { counselorSlotId }
      });

      if (isBooked) {
        return isBooked;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  /**
   * Recupera i dettagli di uno slot a cui l'utente è prenotato, con tutti i suoi dati
   *
   * @param counselorSlotId
   * @returns CounselorSlot
   */
  public async getUserSlotReservation(counselorSlotId: string) {
    try {
      const counselorSlot = await this.httpClient<CounselorSlot & { isPossibleToCancel: boolean }>("/counselorSlot/user/get", {
        path: [counselorSlotId],
        preventFastFlickering: true
      });

      if (counselorSlot) {
        return counselorSlot;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return {} as CounselorSlot & { isPossibleToCancel: boolean };
  }

  /**
   * Recupera i dettagli di uno slot assegnato al counselor loggato e per cui esiste un utente prenotato, con tutti i suoi dati
   *
   * @param counselorSlotId
   * @returns CounselorSlot
   */
  public async getCounselorSlotReservation(counselorSlotId: string) {
    try {
      const counselorSlot = await this.httpClient<CounselorSlot & { isPossibleToCancel: boolean }>("/counselorSlot/counselor/get", {
        path: [counselorSlotId],
        preventFastFlickering: true
      });

      if (counselorSlot) {
        return counselorSlot;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return {} as CounselorSlot & { isPossibleToCancel: boolean };
  }
  /**
  * Recupera i dettagli di uno slot attraverso l'id dello slot
  *
  * @param counselorSlotId
  * @returns CounselorSlot
  */
  public async getAdminSlotReservation(counselorSlotId: string) {
    try {
      const counselorSlot = await this.httpClient<CounselorSlot & { isPossibleToCancel: boolean }>("/counselorSlot/admin/get", {
        path: [counselorSlotId],
        preventFastFlickering: true
      });

      if (counselorSlot) {
        return counselorSlot;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return {} as CounselorSlot & { isPossibleToCancel: boolean };
  }


  /**
   * Conta i counselor visibili dall'admin nel backoffice
   *
   * @returns number
   */
  public async consuelorAdminCount(searchedText?: string) {
    try {
      const counselorSlot = await this.httpClient<number>("/counselor/admin/count", {
        query: { searchedText }
      });

      if (counselorSlot >= 0) {
        return counselorSlot;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return 0;
  }

  /**
   * Restituisce i counselor visibili dall'admin nel backoffice
   *
   * @param searchedText
   * @param fromRecord
   * @param numRecords
   * @returns Counselor[]
   */
  public async consuelorAdminList(searchedText?: string, fromRecord?: number, numRecords?: number) {
    try {
      const counselorSlot = await this.httpClient<Counselor[]>("/counselor/admin/search", {
        query: { searchedText, fromRecord, numRecords },
        preventFastFlickering: true
      });

      if (counselorSlot) {
        return counselorSlot;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  /**
   * Restituisce i counselor visibili dall'admin nel backoffice
   *
   * @param searchedText
   * @param fromRecord
   * @param numRecords
   * @returns Counselor[]
   */
  public async getCurrentlyBookedSlotId() {
    try {
      const counselorSlot = await this.httpClient<string>("counselorSlot/user/getCurrentlyBookedSlotId");

      if (counselorSlot == null || counselorSlot == undefined || counselorSlot) {
        return counselorSlot;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }

  /**
   * Recupera la lista degli utenti e dei manager per DB development
   *
   * @param params
   * @returns []
   */
  public async adminDBDevelopmentDataList(fromRecord?: number, numRecords?: number, cid?: string, surname?: string, managerSurname?: string): Promise<RisultatoListaUtentiEstesa> {
    try {
      const response = await this.httpClient<RisultatoListaUtentiEstesa>("/user/anagrafica/list", {
        query: { cid, surname, managerSurname, fromRecord, numRecords },
        preventFastFlickering: true
      });

      if (response) {
        return response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return new RisultatoListaUtentiEstesa();
  }


  public async adminDBDevelopmentDataGroups(userId: number) {
    try {
      const listDBDevelopmentDataGroupsRes = await this.httpClient<{ groups: ActiveGroup[] }>(`/user/groups/get/${userId}`, {
        method: "get"
      });

      if (listDBDevelopmentDataGroupsRes) {
        return listDBDevelopmentDataGroupsRes.groups;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  public async adminDBDevelopmentEditDataGroups(body: object) {
    try {
      const listDBDevelopmentDataGroupsRes = await this.httpClient<{ groups: [] }>("user/groups/set", {
        method: "post",
        body: body
      });

      if (listDBDevelopmentDataGroupsRes) {
        return listDBDevelopmentDataGroupsRes.groups;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  public async adminDBDevelopmentPerformanceData(userId: number) {
    try {
      const response = await this.httpClient<PerformanceData>(`user/performancemanagement/get/${userId}`);

      if (response) {
        return response;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async adminDBDevelopmentPostPerformanceData(body: object) {
    try {
      const response = await this.httpClient<never>("user/performancemanagement/set", { method: "post", body: body });

      if (response) {
        return response;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async adminDBDevelopmentUserInfo(id: number | string) {
    try {
      const UserInfoData = await this.httpClient<ExtendedUtentiAnagraficaResponse>(`user/anagrafica/get/${id}`);

      if (UserInfoData) {
        return UserInfoData;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async adminDBDevelopmentUserBreadcrumbs(cid: number | string) {
    try {
      const result = await this.httpClient<UserBreadcrumbsResponse>(`user/anagrafica/details/get/${cid}`);

      if (result) {
        return result;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }


  public async adminPostDBDevelopmentUserInfo(body: object) {
    try {
      const response = await this.httpClient<never>("user/anagrafica/set", { method: "post", body: body });

      if (response) {
        return response;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async getUsersEditAccess(body: object) {
    try {
      const response = await this.httpClient<never>("user/tabs/edit/set", {
        method: "post",
        body: body
      });

      if (response) {
        return response;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return null;
  }

  public async getGroupsEditAccess(body: object) {
    try {
      const response = await this.httpClient<{ groups: GroupRes[] }>("user/groups/edit/set", {
        method: "post",
        body: body
      });

      if (response) {
        return response;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }
}
